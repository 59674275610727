<template>
  <div class="eyeBiologyTest">
    <h3><PopularScience :text="'泪膜检查'" :gjz="'leimojiancha'"></PopularScience></h3>
    <div style="text-align: left;"> <el-button type="info">读取文件</el-button></div>

    <ul class="xxxx">
      <li></li>
      <li class="titles">
        <p>Schirmer试验</p>
        <p>*参考值：10-25mm/5min</p>
      </li>
      <li class="titles">
        <p>BUT</p>
        <p>*参考值：≥10s</p>
      </li>
    </ul>
    <ul class="xxxx">
      <li>OD</li>
      <li>
        <el-input class="pj" @keydown.native="keyd($event)" :class="[srval==='CL_RE_TFE_OD_Schirmer5'?'xz':'']" @focus="getgb(postData.CL_RE_TFE_OD_Schirmer5, 'CL_RE_TFE_OD_Schirmer5')" @change="zh('CL_RE_TFE_OD_Schirmer5')" v-model="postData.CL_RE_TFE_OD_Schirmer5" placeholder="请输入内容"></el-input>
        <span>mm</span>
      </li>
      <li>
        <el-input class="pj" @keydown.native="keyd($event)" :class="[srval==='CL_RE_TFE_OD_BUT'?'xz':'']" @focus="getgb(postData.CL_RE_TFE_OD_BUT, 'CL_RE_TFE_OD_BUT')" @change="zh('CL_RE_TFE_OD_BUT')" v-model="postData.CL_RE_TFE_OD_BUT" placeholder="请输入内容"></el-input>
        <span>S</span>
      </li>
    </ul>
    <ul class="xxxx">
      <li>OS</li>
      <li>
        <el-input class="pj" @keydown.native="keyd($event)" :class="[srval==='CL_RE_TFE_OS_Schirmer5'?'xz':'']" @focus="getgb(postData.CL_RE_TFE_OS_Schirmer5, 'CL_RE_TFE_OS_Schirmer5')" @change="zh('CL_RE_TFE_OS_Schirmer5')" v-model="postData.CL_RE_TFE_OS_Schirmer5" placeholder="请输入内容"></el-input>
        <span>mm</span>
      </li>
      <li>
        <el-input class="pj" @keydown.native="keyd($event)" :class="[srval==='CL_RE_TFE_OS_BUT'?'xz':'']" @focus="getgb(postData.CL_RE_TFE_OS_BUT, 'CL_RE_TFE_OS_BUT')" @change="zh('CL_RE_TFE_OS_BUT')" v-model="postData.CL_RE_TFE_OS_BUT" placeholder="请输入内容"></el-input>
        <span>S</span>
      </li>
    </ul>

    <div class="tp">
      <UPfile :type="'TFE'"></UPfile>
<!--      <el-upload-->
<!--          action="#"-->
<!--          list-type="picture-card"-->
<!--          :auto-upload="false">-->
<!--        <i slot="default" class="el-icon-plus"></i>-->
<!--        <div slot="file" slot-scope="{file}">-->
<!--          <img-->
<!--              class="el-upload-list__item-thumbnail"-->
<!--              :src="file.url" alt=""-->
<!--          >-->
<!--          <span class="el-upload-list__item-actions">-->
<!--        <span-->
<!--            class="el-upload-list__item-preview"-->
<!--            @click="handlePictureCardPreview(file)"-->
<!--        >-->
<!--          <i class="el-icon-zoom-in"></i>-->
<!--        </span>-->
<!--        <span-->
<!--            v-if="!disabled"-->
<!--            class="el-upload-list__item-delete"-->
<!--            @click="handleDownload(file)"-->
<!--        >-->
<!--          <i class="el-icon-download"></i>-->
<!--        </span>-->
<!--        <span-->
<!--            v-if="!disabled"-->
<!--            class="el-upload-list__item-delete"-->
<!--            @click="handleRemove(file)"-->
<!--        >-->
<!--          <i class="el-icon-delete"></i>-->
<!--        </span>-->
<!--      </span>-->
<!--        </div>-->
<!--      </el-upload>-->
<!--      <el-dialog :visible.sync="dialogVisible">-->
<!--        <img width="100%" :src="dialogImageUrl" alt="">-->
<!--      </el-dialog>-->
    </div>

    <div class="bz">
      <p>备注：</p>
      <el-input
          type="textarea"
          :autosize="{ minRows: 4, maxRows: 6}"
          placeholder="请输入内容"
          v-model="postData.CL_RE_TFE_Remark">
      </el-input>
    </div>


    <KEYS v-if="showkeys" :keyval="keyval" @changeval="changeval"></KEYS>
  </div>
</template>

<script>
export default {
  name: "appearsTest",
  data() {
    return {
      postData: {},
      dialogImageUrl: '',
      disabled: false,
      dialogVisible: false,


      showdrsb: false,
      showkeys: false,
      issr: false,
      srval: '',
      keyval: '',
      iszf: 0,
    }
  },
  watch: {
    postData: {
      handler(n , o) {
        this.$store.commit('eyeTest/upAppearsTest', n)
      },
      deep: true
    }
  },
  created() {
    document.addEventListener('click', this.getXY, false)
    if (!this.$store.state.users.CsUser.CustomerId) {return false}
    this._api.eyeTest.getAppearsTest()
        .then(res => {
          if (res.GetListResult && res.GetListResult.length > 0) {
            this.postData = this._clJson(res.GetListResult[0].S_OP_Json)
          }
        })
  },
  methods: {
    zh(key) {
      this.postData[key] = this.postData[key]?this.postData[key].toString():''
    },



    keyd(e) {
      // e.returnValue= false
    },

    srjc(val, min, max) {
      if (val) {
        if (val.indexOf('^') > -1) return false
        if (isNaN(Number(val))) return true
        if (Number(val) > Number(max)) return true
        if (Number(val) < Number(min)) return true
        return false
      }
      return false
    },

    getgb(str, key, iszf) {
      // console.log(e)
      // document.activeElement.blur();
      this.keyval = str
      this.srval = key
      this.issr = true
      this.iszf = iszf
    },

    getXY(e) {
      if (this.issr) {
        this.$store.commit('isShow/upkeyh', e.y)
        this.showkeys = true
        this.issr = false
      }else  {
        this.showkeys = false
        this.srval = ''
      }

    },

    changeval(e) {
      this.keyval = e
      this.$set(this.postData, this.srval, e)
      if (this.postData[this.srval] && !isNaN(Number(this.postData[this.srval])) && this.iszf) {
        if (this.postData[this.srval] && Number(this.postData[this.srval]) > 0 && this.postData[this.srval].indexOf('+') < 0  && this.postData[this.srval].indexOf('-') < 0) {
          this.$set(this.postData, this.srval, '+'+e)
        }
      }
    }
  },
  beforeDestroy() {
    document.removeEventListener('click', this.getXY)
  },
  components: {
    KEYS: () => import('@/components/keyboard/keyHome'),
    UPfile: () => import('@/components/CommonComponents/upFile')
  }
}
</script>

<style scoped lang="scss">
.eyeBiologyTest {
  width: 90%;
  margin: 0 auto;
}
h3{padding-bottom: 0.3rem}
.xxxx {
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  padding-top: 20px;
  li {
    width: 14vw;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  li:nth-child(1) {
    justify-content: start;
    width: 5%;
  }
  .w15 {width: 15vw;}
  .pj {margin: 0.03rem 0.2rem; margin-right: 0.05rem;}
  .titles {
    display: block;
    p:nth-child(2) {
      font-size: 12px;
      color: #40a7f6;
    }
  }
}


::v-deep  .xxxx li input:hover{background: #e8e6e6}
::v-deep .xz input {background: #e8e6e6}



.tp {
  display: flex;
  justify-content: start;
  padding-top: 0.4rem;
}
.bz {
  text-align: left;
  padding-top: 50px;
  padding-bottom: 30px;
}
.bz p {padding-bottom: 10px;font-size: 17px}
</style>
